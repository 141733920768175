import { Component, OnInit } from '@angular/core';
import { EmailService } from '../email-service.service';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {

  routerLinkHome = '/home';
  routerLinkAbout = '/about';
  routerLinkContact = '/contact';
  routerLinkGallery = '/gallery';
  constructor(private emailService: EmailService) { }

  ngOnInit(): void {
  }

  subscribeEmail() {
    
  }

  sendEmail() {
    
  }
}
