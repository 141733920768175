import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";

@Component({
  selector: 'app-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.scss']
})
export class IndexComponent implements OnInit {
  routerLinkHome = '/home';
  routerLinkAbout = '/about';
  routerLinkContact = '/contact';
  routerLinkGallery = '/gallery';

  constructor() { }

  ngOnInit(): void {

  }

  subscribeEmail() {

  }

}
