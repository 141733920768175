import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {IndexComponent} from "./index/index.component";
import {AboutComponent} from "./about/about.component";
import {GalleryComponent} from "./gallery/gallery.component";
import {ContactComponent} from "./contact/contact.component";

const routes: Routes = [
  {
    path: '', component: IndexComponent
  },  {
    path: 'home', component: IndexComponent
  }, {
    path: 'about', component: AboutComponent
  }, {
    path: 'gallery', component: GalleryComponent
  }, {
    path: 'contact', component: ContactComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
