import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent implements OnInit {

  routerLinkHome = '/home';
  routerLinkAbout = '/about';
  routerLinkContact = '/contact';
  routerLinkGallery = '/gallery';
  constructor() { }

  ngOnInit(): void {
    console.log('here')
  }

  subscribeEmail() {
    
  }
}
