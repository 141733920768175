<!DOCTYPE html>
<html lang="en">

<head>
  <title>NW Gear Repairs - Contact</title>
  <meta charset="utf-8">
  <meta name="viewport" content="width=device-width, initial-scale=1">



  <!-- Load map styles -->
  <!--

  TemplateMo 559 NW Gear Repairs

  https://templatemo.com/tm-559-zay-shop

  -->
</head>

<body>
<!-- Start Top Nav -->
<nav class="navbar navbar-expand-lg bg-dark navbar-light d-none d-lg-block" id="templatemo_nav_top">
  <div class="container text-light">
    <div class="w-100 d-flex justify-content-between">
      <div>
        <i class="fa fa-envelope mx-2"></i>
        <a class="navbar-sm-brand text-light text-decoration-none" href="mailto:nwratte.repairs@gmail.com">nwratte.repairs@gmail.com</a>
        <i class="fa fa-phone mx-2"></i>
        <a class="navbar-sm-brand text-light text-decoration-none" href="tel:072-709-4961">072-709-4961</a>
      </div>
      <div>
        <a class="text-light" href="https://www.facebook.com/NW-Gear-Repairs-110913583952298/" target="_blank" rel="sponsored"><i class="fab fa-facebook-f fa-sm fa-fw me-2"></i></a>
        <a class="text-light" href="https://www.instagram.com/" target="_blank"><i class="fab fa-instagram fa-sm fa-fw me-2"></i></a>
        <a class="text-light" href="https://twitter.com/" target="_blank"><i class="fab fa-twitter fa-sm fa-fw me-2"></i></a>
        <a class="text-light" href="https://www.linkedin.com/" target="_blank"><i class="fab fa-linkedin fa-sm fa-fw"></i></a>
      </div>
    </div>
  </div>
</nav>
<!-- Close Top Nav -->


<!-- Header -->
<nav class="navbar navbar-expand-lg navbar-light shadow">
  <div class="container d-flex justify-content-between align-items-center">

    <a class="navbar-brand text-success logo h1 align-self-center" href="index.html">
      NW Gear Repairs
      <h3 class="h2">We will make it work.. </h3>
    </a>

    <button class="navbar-toggler border-0" type="button" data-bs-toggle="collapse" data-bs-target="#templatemo_main_nav" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>

    <div class="align-self-center collapse navbar-collapse flex-fill  d-lg-flex justify-content-lg-between" id="templatemo_main_nav">
      <div class="flex-fill">
        <ul class="nav navbar-nav d-flex justify-content-between mx-lg-auto">
          <li class="nav-item">
            <a class="nav-link"  [routerLink]="routerLinkHome">Home</a>
          </li>
          <li class="nav-item">
            <a class="nav-link"  [routerLink]="routerLinkAbout">About</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" [routerLink]="routerLinkGallery">Gallery</a>
          </li>
          <li class="nav-item">
            <a class="nav-link"  [routerLink]="routerLinkContact">Contact</a>
          </li>
        </ul>
      </div>

    </div>

  </div>
</nav>
<!-- Close Header -->

<!-- Modal -->
<div class="modal fade bg-white" id="templatemo_search" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document">
    <div class="w-100 pt-1 mb-5 text-right">
      <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
    </div>
    <form action="" method="get" class="modal-content modal-body border-0 p-0">
      <div class="input-group mb-2">
        <input type="text" class="form-control" id="inputModalSearch" name="q" placeholder="Search ...">
        <button type="submit" class="input-group-text bg-success text-light">
          <i class="fa fa-fw fa-search text-white"></i>
        </button>
      </div>
    </form>
  </div>
</div>


<!-- Start Content Page -->
<div class="container-fluid bg-light py-5">
  <div class="col-md-6 m-auto text-center">
    <h1 class="h1">Contact Us</h1>
    <p>
      Please feel free to call or e-mail us if you have any questions:
      
    </p>
      <ul class="list-unstyled text-light footer-link-list">
     
        <li>
          <i class="fa fa-phone fa-fw"></i>
          <a class="text-decoration-none" href="tel:072-709-4961">072-709-4961</a>
        </li>
        <li>
          <i class="fa fa-envelope fa-fw"></i>
          <a class="text-decoration-none" href="mailto:nwratte.repairs@gmail.com">nwratte.repairs@gmail.com</a>
        </li>
      </ul>
   
  </div>
</div>


<!-- Start Contact -->
<div class="container py-5">
  <div class="row py-5">
    <!-- <form class="col-md-9 m-auto" method="post" role="form">
      <div class="row">
        <div class="form-group col-md-6 mb-3">
          <label class="m-2" for="inputname">Name</label>
          <input type="text" class="form-control" id="name" name="name" placeholder="Name">
        </div>
        <div class="form-group col-md-6 mb-3">
          <label  class="m-2" for="inputemail">Email</label>
          <input type="email" class="form-control" id="email" name="email" placeholder="Email">
        </div>
      </div>
      <div class="mb-3">
        <label class="m-2" for="inputsubject">Subject</label>
        <input type="text" class="form-control " id="subject" name="subject" placeholder="Subject">
      </div>
      <div class="mb-3">
        <label class="m-2" for="inputmessage">Message</label>
        <textarea class="form-control" id="message" name="message" placeholder="Message" rows="8"></textarea>
      </div>
      <div class="row">
        <div class="col text-end mt-2">
          <button type="button" class="btn btn-success btn-lg px-3" (click)="sendEmail()">Let’s Talk</button>
        </div>
      </div>
    </form> -->
  </div>
</div>
<!-- End Contact -->


<!-- Start Footer -->
<footer class="bg-dark" id="tempaltemo_footer">
  <div class="container">
    <div class="row">

      <div class="col-md-4 pt-5">
        <h2 class="h2 text-success border-bottom pb-3 border-light logo">NW Gear Repairs</h2>
        <ul class="list-unstyled text-light footer-link-list">
          <li>
            <i class="fas fa-map-marker-alt fa-fw"></i>
            Klerksdorp
          </li>
          <li>
            <i class="fa fa-phone fa-fw"></i>
            <a class="text-decoration-none" href="tel:072-709-4961">072-709-4961</a>
          </li>
          <li>
            <i class="fa fa-envelope fa-fw"></i>
            <a class="text-decoration-none" href="mailto:nwratte.repairs@gmail.com">nwratte.repairs@gmail.com</a>
          </li>
        </ul>
      </div>
    </div>

    <div class="row text-light mb-4">
      <div class="col-12 mb-3">
        <div class="w-100 my-3 border-top border-light"></div>
      </div>
   
      </div>
      <div class="col-auto" style="margin-left: 40%;">
        <div class="col-auto me-auto">
          <ul class="list-inline text-left footer-icons">
            <li class="list-inline-item border border-light rounded-circle text-center">
              <a class="text-light text-decoration-none" target="_blank" href="https://www.facebook.com/NW-Gear-Repairs-110913583952298"><i class="fab fa-facebook-f fa-lg fa-fw"></i></a>
            </li>
            <li class="list-inline-item border border-light rounded-circle text-center">
              <a class="text-light text-decoration-none" target="_blank" href="https://www.instagram.com/"><i class="fab fa-instagram fa-lg fa-fw"></i></a>
            </li>
            <li class="list-inline-item border border-light rounded-circle text-center">
              <a class="text-light text-decoration-none" target="_blank" href="https://twitter.com/"><i class="fab fa-twitter fa-lg fa-fw"></i></a>
            </li>
            <li class="list-inline-item border border-light rounded-circle text-center">
              <a class="text-light text-decoration-none" target="_blank" href="https://www.linkedin.com/"><i class="fab fa-linkedin fa-lg fa-fw"></i></a>
            </li>
          </ul>
 
      </div>
    </div>
  </div>

  <div class="w-100 bg-black py-3">
    <div class="container">
      <div class="row pt-2">
        <div class="col-12">
          <p class="text-left text-light">
           NW Gear Repairs
          </p>
        </div>
      </div>
    </div>
  </div>

</footer>
<!-- End Footer -->

<!-- Start Script -->

<!-- End Script -->
</body>

</html>
