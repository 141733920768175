<!DOCTYPE html>
<html lang="en">

<head>
  <title>NW Gear Repairs</title>
  <meta charset="utf-8">
  <meta name="viewport" content="width=device-width, initial-scale=1">


  <!--

  TemplateMo 559 NW Gear Repairs

  https://templatemo.com/tm-559-zay-shop

  -->
</head>

<body>
<!-- Start Top Nav -->
<nav class="navbar navbar-expand-lg bg-dark navbar-light d-none d-lg-block" id="templatemo_nav_top">
  <div class="container text-light">
    <div class="w-100 d-flex justify-content-between">
      <div>
        <i class="fa fa-envelope mx-2"></i>
        <a class="navbar-sm-brand text-light text-decoration-none" href="mailto:nwratte.repairs@gmail.com">nwratte.repairs@gmail.com</a>
        <i class="fa fa-phone mx-2"></i>
        <a class="navbar-sm-brand text-light text-decoration-none" href="tel:072-709-4961">072-709-4961</a>
      </div>
      <div>
        <a class="text-light" href="https://www.facebook.com/NW-Gear-Repairs-110913583952298/" target="_blank" rel="sponsored"><i class="fab fa-facebook-f fa-sm fa-fw me-2"></i></a>
        <a class="text-light" href="https://www.instagram.com/" target="_blank"><i class="fab fa-instagram fa-sm fa-fw me-2"></i></a>
        <a class="text-light" href="https://twitter.com/" target="_blank"><i class="fab fa-twitter fa-sm fa-fw me-2"></i></a>
        <a class="text-light" href="https://www.linkedin.com/" target="_blank"><i class="fab fa-linkedin fa-sm fa-fw"></i></a>
      </div>
    </div>
  </div>
</nav>
<!-- Close Top Nav -->


<!-- Header -->
<nav class="navbar navbar-expand-lg navbar-light shadow">
  <div class="container d-flex justify-content-between align-items-center">

    <a class="navbar-brand text-success logo h1 align-self-center" href="index.html">
      NW Gear Repairs
      <h3 class="h2">We will make it work.. </h3>
    </a>
    

    <button class="navbar-toggler border-0" type="button" data-bs-toggle="collapse" data-bs-target="#templatemo_main_nav" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>

    <div class="align-self-center collapse navbar-collapse flex-fill  d-lg-flex justify-content-lg-between" id="templatemo_main_nav">
      <div class="flex-fill">
        <ul class="nav navbar-nav d-flex justify-content-between mx-lg-auto">
          <li class="nav-item">
            <a class="nav-link"  [routerLink]="routerLinkHome">Home</a>
          </li>
          <li class="nav-item">
            <a class="nav-link"  [routerLink]="routerLinkAbout">About</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" [routerLink]="routerLinkGallery">Gallery</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" [routerLink]="routerLinkContact">Contact</a>
          </li>
        </ul>
      </div>

    </div>

  </div>
</nav>
<!-- Close Header -->

<!-- Modal -->
<div class="modal fade bg-white" id="templatemo_search" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document">
    <div class="w-100 pt-1 mb-5 text-right">
      <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
    </div>
    <form action="" method="get" class="modal-content modal-body border-0 p-0">
      <div class="input-group mb-2">
        <input type="text" class="form-control" id="inputModalSearch" name="q" placeholder="Search ...">
        <button type="submit" class="input-group-text bg-success text-light">
          <i class="fa fa-fw fa-search text-white"></i>
        </button>
      </div>
    </form>
  </div>
</div>



<!-- Start Banner Hero -->
<div id="template-mo-zay-hero-carousel" class="carousel slide" data-bs-ride="carousel">
  <ol class="carousel-indicators">
    <li data-bs-target="#template-mo-zay-hero-carousel" data-bs-slide-to="0" class="active"></li>
    <li data-bs-target="#template-mo-zay-hero-carousel" data-bs-slide-to="1"></li>
    <li data-bs-target="#template-mo-zay-hero-carousel" data-bs-slide-to="2"></li>
  </ol>
  <div class="carousel-inner">
    <div class="carousel-item active">
      <div class="container">
        <div class="row p-5">
          <div class="mx-auto col-md-8 col-lg-6 order-lg-last">
            <img class="img-fluid" width="408" height="408" src="./assets/img/gear.jpg" alt="">
          </div>
          <div class="col-lg-6 mb-0 d-flex align-items-center">
            <div class="text-align-left align-self-center">
              <h1 class="h1 text-success"><b>NW</b> Gear Repairs</h1>
              <h3 class="h2">MMS Conical gear</h3>
              <p>
                <!-- NW Gear Repairs is an eCommerce HTML5 CSS template with latest version of Bootstrap 5 (beta 1).
                This template is 100% free provided by <a rel="sponsored" class="text-success" href="https://templatemo.com" target="_blank">TemplateMo</a> website.
                Image credits go to <a rel="sponsored" class="text-success" href="https://stories.freepik.com/" target="_blank">Freepik Stories</a>,
                <a rel="sponsored" class="text-success" href="https://unsplash.com/" target="_blank">Unsplash</a> and
                <a rel="sponsored" class="text-success" href="https://icons8.com/" target="_blank">Icons 8</a>. -->
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="carousel-item">
      <div class="container">
        <div class="row p-5">
          <div class="mx-auto col-md-8 col-lg-6 order-lg-last">
            <img class="img-fluid" width="408" height="408" src="../../assets/img/gear-spline-shaft.jpg" alt="">
          </div>
          <div class="col-lg-6 mb-0 d-flex align-items-center">
            <div class="text-align-left">
              <h1 class="h1 text-success"><b>NW</b> Gear Repairs</h1>
              <h3 class="h2">Spline Shaft Gear</h3>
              <!-- <p>
                You are permitted to use this Zay CSS template for your commercial websites.
                You are <strong>not permitted</strong> to re-distribute the template ZIP file in any kind of template collection websites.
              </p> -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="carousel-item">
      <div class="container">
        <div class="row p-5">
          <div class="mx-auto col-md-8 col-lg-6 order-lg-last">
            <img class="img-fluid" width="408" height="408" src="../../assets/img/synchro-gears.png" alt="">
          </div>
          <div class="col-lg-6 mb-0 d-flex align-items-center">
            <div class="text-align-left">
              <h1 class="h1 text-success"><b>NW</b> Gear Repairs</h1>
              <h3 class="h2">Synchro Gears and much more... </h3>
              <!-- <p>
                We bring you 100% free CSS templates for your websites.
                If you wish to support TemplateMo, please make a small contribution via PayPal or tell your friends about our website. Thank you.
              </p> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <a class="carousel-control-prev text-decoration-none w-auto ps-3" href="#template-mo-zay-hero-carousel" role="button" data-bs-slide="prev">
    <i class="fas fa-chevron-left"></i>
  </a>
  <a class="carousel-control-next text-decoration-none w-auto pe-3" href="#template-mo-zay-hero-carousel" role="button" data-bs-slide="next">
    <i class="fas fa-chevron-right"></i>
  </a>
</div>
<!-- End Banner Hero -->


<!-- Start Categories of The Month -->
<section class="container py-5">
  <div class="row text-center pt-3">
    <div class="col-lg-6 m-auto">
   
    </div>
  </div>
  <div class="row">
    <div class="col-12 col-md-4 p-5 mt-3">
      <a href="#"><img  height="222" src="" class="rounded-circle img-fluid border"></a>

    </div>
    <div class="col-12 col-md-4 p-5 mt-3">
      <a href="#"><img src="" class="rounded-circle img-fluid border"></a>

    </div>
    <div class="col-12 col-md-4 p-5 mt-3">
      <a href="#"><img src="" class="rounded-circle img-fluid border"></a>
      
    </div>
  </div>
</section>
<!-- End Categories of The Month -->





<!-- Start Footer -->
<footer class="bg-dark" id="tempaltemo_footer">
  <div class="container">
    <div class="row">

      <div class="col-md-4 pt-5">
        <h2 class="h2 text-success border-bottom pb-3 border-light logo">NW Gear Repairs</h2>
        <ul class="list-unstyled text-light footer-link-list">
          <li>
            <i class="fas fa-map-marker-alt fa-fw"></i>
            Klerksdorp
          </li>
          <li>
            <i class="fa fa-phone fa-fw"></i>
            <a class="text-decoration-none" href="tel:072-709-4961">072-709-4961</a>
          </li>
          <li>
            <i class="fa fa-envelope fa-fw"></i>
            <a class="text-decoration-none" href="mailto:nwratte.repairs@gmail.com">nwratte.repairs@gmail.com</a>
          </li>
        </ul>
      </div>


    </div>

    <div class="row text-light mb-4">
      <div class="col-12 mb-3">
        <div class="w-100 my-3 border-top border-light"></div>
      </div>
   
      </div>
      <div class="col-auto" style="margin-left: 40%;">
        <div class="col-auto me-auto">
          <ul class="list-inline text-left footer-icons">
            <li class="list-inline-item border border-light rounded-circle text-center">
              <a class="text-light text-decoration-none" target="_blank" href="https://www.facebook.com/NW-Gear-Repairs-110913583952298"><i class="fab fa-facebook-f fa-lg fa-fw"></i></a>
            </li>
            <li class="list-inline-item border border-light rounded-circle text-center">
              <a class="text-light text-decoration-none" target="_blank" href="https://www.instagram.com/"><i class="fab fa-instagram fa-lg fa-fw"></i></a>
            </li>
            <li class="list-inline-item border border-light rounded-circle text-center">
              <a class="text-light text-decoration-none" target="_blank" href="https://twitter.com/"><i class="fab fa-twitter fa-lg fa-fw"></i></a>
            </li>
            <li class="list-inline-item border border-light rounded-circle text-center">
              <a class="text-light text-decoration-none" target="_blank" href="https://www.linkedin.com/"><i class="fab fa-linkedin fa-lg fa-fw"></i></a>
            </li>
          </ul>
 
      </div>
    </div>
  </div>

  <div class="w-100 bg-black py-3">
    <div class="container">
      <div class="row pt-2">
        <div class="col-12">
          <p class="text-left text-light">
            NW Gear Repairs
          </p>
        </div>
      </div>
    </div>
  </div>

</footer>
<!-- End Footer -->

<!-- Start Script -->

<!-- End Script -->
</body>

</html>
