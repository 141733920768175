import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class EmailService {
  test(arg0: string) {
    return null;
  }

  constructor(private httpClient: HttpClient) { }


}
